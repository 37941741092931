body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -ms-overflow-style: none;
  overflow-x: hidden;
}

body::-webkit-scrollbar{
  display: none;
}

.stop-scrolling {
  overflow-y: hidden;
}

@keyframes slideRight {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
}

.animateLast {
  animation: slideRight .5s;
}

@keyframes slideLeft {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
}

.animateFirst {
  animation: slideLeft .5s;
}

@keyframes appear {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.loader {
  width: 100%;
  height: 100%;
  animation: appear 2.2s;
}

@keyframes home {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.home-page {
  animation: home .5s;
}
